import { cva } from 'class-variance-authority';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import type { HeadingProps } from '@/components/common/Heading/Heading.types';

export const Heading = ({ children, variant, className, ...rest }: HeadingProps) => {
  const Tag = variant;
  const classVariants = useMemo(() => cva(className, {
    variants: {
      variant: {
        h1: 'text-lg',
        h2: 'text-base',
        h3: '',
        h4: '',
      },
    },
  }), [className]);

  return (
    <Tag
      {...rest}
      className={twMerge('font-medium', classVariants({
        variant,
      }), className)}
    >
      {children}
    </Tag>
  );
};
